// extracted by mini-css-extract-plugin
export var appDownload = "style-module--appDownload--076db";
export var block = "style-module--block--7d1d2";
export var blockContainer = "style-module--blockContainer--317a5";
export var button = "style-module--button--2cf00";
export var buttonBlog = "style-module--buttonBlog--74831";
export var buttonContainer = "style-module--buttonContainer--4cd97";
export var buttonGreen = "style-module--buttonGreen--ce603";
export var buttonWhite = "style-module--buttonWhite--591c2";
export var container = "style-module--container--271fd";
export var deviceImageDesktop = "style-module--deviceImageDesktop--319e7";
export var deviceImageMobile = "style-module--deviceImageMobile--58074";
export var fadeInAnimation = "style-module--fadeInAnimation--1ba44";
export var footerButtons = "style-module--footerButtons--a1f21";
export var forDogOwners = "style-module--forDogOwners--87b69";
export var form = "style-module--form--d8151";
export var h1 = "style-module--h1--d8ef7";
export var h2 = "style-module--h2--b043f";
export var h3 = "style-module--h3--70e25";
export var heroContainer = "style-module--heroContainer--0bbda";
export var howItWorks = "style-module--howItWorks--9c955";
export var image = "style-module--image--b333d";
export var imageContainer = "style-module--imageContainer--c7a5b";
export var input = "style-module--input--276cc";
export var inputGroup = "style-module--inputGroup--ccc83";
export var inputRow = "style-module--inputRow--218d3";
export var inputWrapper = "style-module--inputWrapper--4a4f4";
export var label = "style-module--label--94be6";
export var link = "style-module--link--b43ac";
export var newHighlight = "style-module--newHighlight--50d4f";
export var subline = "style-module--subline--af2ee";
export var textBlock = "style-module--textBlock--36bed";
export var textBlockContainer = "style-module--textBlockContainer--0b90c";
export var textContainer = "style-module--textContainer--cb8c4";
export var textSection = "style-module--textSection--8fa78";
import AddressAutofill from '../../AddressAutofill'
import DateRangeInput from '../../DateRangeInput'
import Template from '../../Template'
import * as style from './style.module.scss'
import { navigate } from 'gatsby'
import Link from 'gatsby-link'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { Helmet } from 'react-helmet'
import { connectSearchBox } from 'react-instantsearch-dom'
import {
  unstable_useFormState as useFormState,
  unstable_Form as Form,
  unstable_FormLabel as FormLabel,
} from 'reakit/Form'

const Doggos = (props) => {
  const form = useFormState({})
  const locationRef = React.useRef()

  return (
    <Template>
      <Helmet>
        <title>Doggos.com</title>
      </Helmet>
      <div className={style.container}>
        <div className={style.heroContainer}>
          <h1 className={style.h1}>
            Book the best care for your dog while you're away
          </h1>
          <h3 className={style.subline}>
            Browse top local dog sitters, including in-home care and boarding
            facilities
          </h3>
        </div>

        <form
          {...form}
          noValidate={false}
          className={style.form}
          onSubmit={(event) => {
            event.preventDefault()
            const data = new FormData(event.target)
            props.refine(data.get('query'))
            navigate(`/search/?l=${data.get('l')}&query=${data.get('query')}`)
          }}
        >
          <div className={style.inputRow}>
            <div className={style.inputGroup}>
              <FormLabel className={style.label}>
                What area should we search?{' '}
              </FormLabel>
              <div className={style.inputWrapper}>
                <AddressAutofill
                  {...form}
                  placeholder='Search by city or state'
                  name='city'
                  placeSelect={(place) => {
                    locationRef.current.value = `${place.properties.lat},${place.properties.lon}`
                  }}
                />
                <input ref={locationRef} type='hidden' name='l' />
              </div>
            </div>
            <div className={style.inputGroup}>
              <FormLabel className={style.label}>
                Drop-off and pick-up dates
              </FormLabel>
              <div className={style.inputRow}>
                <DateRangeInput attribute='unavailability' />
              </div>
            </div>
          </div>
          <div className={style.inputRow}>
            <button className={style.button} type='submit'>
              Browse dog sitters
            </button>
          </div>
        </form>
        <div className={style.howItWorks}>
          <h2 className={style.h2}>How it works</h2>
          <div className={style.blockContainer}>
            <div className={style.block}>
              <div className={style.image}>
                <StaticImage placeholder='none' src='1.png' height='220' />
              </div>
              <h3 className={style.h3}>1. Browse sitters</h3>
              <p>
                Find pet care your dog will love with filters for location,
                accommodation, exercise needs, and more
              </p>
            </div>
            <div className={style.block}>
              <div className={style.image}>
                <StaticImage placeholder='none' src='2.png' height='220' />
              </div>
              <h3 className={style.h3}>2. Chat and book</h3>
              <p>
                Use our secure chat to ask questions before you book, or easily
                reach out to the doggos.com support team
              </p>
            </div>
            <div className={style.block}>
              <div className={style.image}>
                <StaticImage placeholder='none' src='3.png' height='220' />
              </div>
              <h3 className={style.h3}>3. Get updates</h3>
              <p>
                Relax knowing you can request photo & videos of your dog or
                update your booking anytime
              </p>
            </div>
          </div>
        </div>
        <div className={style.textSection}>
          <h2 className={style.h2}>
            The <span className={style.newHighlight}>new</span> way to find &
            book <span className={style.highlight}>loving</span> care for your
            dog
          </h2>
          <p>Platform features include:</p>
          <div className={style.textBlockContainer}>
            <div className={style.textBlock}>
              <h3 className={style.h3}>Veterinary reimbursement</h3>
              <p>
                All reservations paid on doggos.com include live chat support
                and $2,500 in veterinary reimbursement, with additional optional
                coverage also available
              </p>
            </div>
            <div className={style.textBlock}>
              <h3 className={style.h3}>Live chat</h3>
              <p>
                Our fast, modern chat allows you to communicate with your sitter
                anywhere in the world. Send photos, videos, reactions, or
                request updates on your dog
              </p>
            </div>
            {/* <div className={style.textBlock}>
              <h3 className={style.h3}>Lowest cost</h3>
              <p>
                Doggos.com has a simple <strong>5% fee</strong> on all bookings
                (less than half other platforms), making us the most affordable
                way to find pet care
              </p>
            </div> */}
            <div className={style.textBlock}>
              <h3 className={style.h3}>Payment protection</h3>
              <p>
                Any reservation paid via doggos.com can be canceled up to 48
                hours before your booking and receive a complete refund with 0
                hidden fees or charges
              </p>
            </div>
            <div className={style.textBlock}>
              <h3 className={style.h3}>New services always being added</h3>
              <p>
                We currently support in-home boarding services, boarding
                facilities, and doggy daycare, with support for grooming,
                training, and dog walking coming very soon
              </p>
            </div>
            {/* <div className={style.textBlock}>
              <h3 className={style.h3}>Cash payments</h3>
              <p>
                Paying cash, check, or in-person with a credit card? No problem.
                You can continue to chat with your sitter and manage your
                booking on doggos.com, completely free
              </p>
            </div> */}
            {/* <div className={style.textBlock}>
              <h3 className={style.h3}>Custom services</h3>
              <p>
                Does your dog need breakfast cooked in the morning? 30 minutes
                of treadmill time? No problem! Custom services are what we are
                built on, so you can easily add or change your dog's
                reservation.
              </p>
            </div> */}
          </div>
        </div>
        {/* <div className={style.container}>
          <div className={style.textContainer}>
            <h2>One platform, more options </h2>
            <div className={style.buttonContainer}>
              <Link className={style.buttonGreen} to={'/search/'}>
                Find a dog sitter
              </Link>
              <Link className={style.buttonWhite} to={'/about/'}>
                About us
              </Link>
            </div>
          </div>
        </div> */}
        <div className={style.appDownload}>
          <div className={style.textContainer}>
            <h2>No app download required</h2>
            <p>
              Doggos works on any internet-connected phone, tablet, or computer.
              Simply login and check on your pup from any device
            </p>
          </div>
          <div className={style.imageContainer}>
            <div className={style.deviceImageDesktop}>
              <StaticImage
                placeholder='none'
                src='cropped_devices.png'
                height='412'
              />
            </div>
            <div className={style.deviceImageMobile}>
              <StaticImage
                placeholder='none'
                src='cropped_devices.png'
                height='250'
              />
            </div>
          </div>
        </div>
      </div>
    </Template>
  )
}

export default connectSearchBox(Doggos)
